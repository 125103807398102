<template>
    <v-container fluid>
      <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
        <h1 class="page-title">Gerenciamento de Ramais em Lote</h1>
        <v-dialog v-model="dialog" max-width="900px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2 ml-2" v-bind="attrs" v-on="on">
                Cadastrar Ramais em Lote
             </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <template>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="max_contacts"
                        label="Maximo de Contatos"
                        type="number"
                        inputmode="numeric"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-model="password"
                        label="Senha"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select
                        v-model="contextId"
                        :items="contexts"
                        item-text="nome"
                         item-value="id"
                        :rules="[(v) => !!v || 'Selecione o Context']"
                        label="Contexts"
                        required
                      ></v-select>
                      <v-text-field
                        v-model="from_domain"
                        label="Dominio"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-model="username"
                        label="Username"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </template>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="warning" @click="resetValidation">
                Limpar
              </v-btn>
              <v-btn color="error" class="mr-4" @click="close">
                Cancelar
              </v-btn>
              <v-btn color="success" class="mr-4" @click="save">
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        
      </v-row>
      <template>
        <v-card color="grey lighten-4" flat height="60px" tile>
          <v-toolbar dense>
            <v-spacer></v-spacer>
            <v-card-title class="pa-1 pb-0">
              <v-spacer></v-spacer>
              <v-text-field
                append-icon="mdi-magnify"
                label="Pesquisar"
                clearable
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
          </v-toolbar>
        </v-card>
      </template>
      <v-data-table :headers="headers" :items="desserts" class="elevation-1">
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small class="mr-2" v-on:click="alertDisplay">
            mdi-delete
          </v-icon>
          <v-icon @click="undo(item)">
            mdi-undo
          </v-icon>
        </template>
      </v-data-table>
    </v-container>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "Maximo Contatos", value: "max_contacts" },
        { text: "Senha", value: "password" },
        { text: "Context", value: "contextId" },
        { text: "Dominio", value: "from_domain" },
        { text: "Username", value: "username"},
        { text: "Ações", value: "actions", sortable: false },
      ],
      desserts: [],
      contexts: [],
      editedIndex: -1,
      editedItem: {
        max_contacts: 0,
        password: "",
        contextId: 0,
        from_domain: "",
        username: "",
      },
      defaultItem: {
        max_contacts: 0,
        password: "",
        contextId: 0,
        from_domain: "",
      },
      valid: false,
      success: false,
      error: false,
      errorMessage: "",
    }),
  
    computed: {
      formTitle() {
        return this.editedIndex === -1 ? "Novo Ramal em Lote" : "Editar Ramal em Lote";
      },
    },
  
    watch: {
      dialog(val) {
        val || this.close();
      },
      dialogDelete(val) {
        val || this.closeDelete();
      },
    },
  
    created() {
      this.initialize();
      this.getDesserts();
      this.getContexts();
    },
  
    methods: {
      initialize() {
        this.desserts = [];
      },
  
      async getContexts() {
        try {
          const response = await axios.get('http://173.212.228.61:3000/api/context');
          this.contexts = response.data;
        } catch (error) {
          console.error("Erro ao carregar contextos:", error);
        }
      },
  
      async getDesserts() {
        try {
          const response = await axios.get('http://173.212.228.61:3000/api/ps_endpoints');
          this.desserts = response.data;
        } catch (error) {
            console.error("Erro:", error);
        }
      },
  
      editItem(item) {
        this.editedIndex = this.desserts.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      },
  
      close() {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },
  
      closeDelete() {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },
  
      save() {
        const token = localStorage.getItem('token'); 
  
        axios.post('http://173.212.228.61:3000/api/ps_endpoints/ramais', {
          id: this.id,
          max_contacts: Number(this.max_contacts),
          password: this.password,
          contextId: this.contextId,
          from_domain: this.from_domain
        }, {
          headers: {
            'authorization': `Bearer ${token}`
          }
        })
        .then(response => {
          this.desserts.push(response.data);
          this.success = true;
          this.$router.push("/Ramais");
        })
        .catch(error => {
          console.error(error);
        });
      },
    
      resetValidation() {
        this.$refs.editedItem.resetValidation();
      },
      undo: function() {
        if (this.canUndo) {
          this.editedIndex -= 1;
          this.desserts = this.history[this.editedIndex];
        }
      },
      alertDisplay() {
        this.$swal({
          title: "Deseja excluir este ramal?",
          text: "O ramal será excluído do banco de dados",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3CD4A0",
          cancelButtonColor: "#E53935",
          confirmButtonText: "Sim, excluir!",
        }).then((result) => {
          if (result.value) {
            this.$swal("Excluído", "Ramal excluído com sucesso!", "success");
          } else {
            this.$swal("Exclusão Cancelada!");
          }
        });
      },
    },
  };
  </script>
  