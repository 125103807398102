<template>
  <v-app>
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="5" class="main-part d-none d-md-none d-lg-flex">
          <div class="d-flex">
            <v-img src="@/assets/logo1.png" contain></v-img>
          </div>
        </v-col> 
        <v-col cols="12" md="7">
          <v-row no-gutters>
            <v-col
              cols="12"
              class="register-part d-flex align-center justify-center"
            >
              <div class="register-wrapper">
                <v-row no-gutters>
                  <h1 class="page-title">Cadastro</h1>
                </v-row>
                <v-tabs grow>
                  <v-tab :href="`#tab-register`"></v-tab>
                  <v-tab-item :value="'tab-register'">
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-container>
                        <v-row class="flex-column">
                          <v-col cols="12" class="d-flex align-center my-8">
                            <span class="px-5"></span>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="user.nome"
                              label="Nome"
                              required
                            ></v-text-field>
                            <v-text-field
                              v-model="user.email"
                              label="Email"
                              required
                            ></v-text-field>
                            <v-text-field
                              v-model="user.telefone"
                              label="Telefone"
                              type="number"
                              inputmode="numeric"
                              required
                            ></v-text-field>
                            <v-text-field
                              v-model="user.password"
                              label="Senha"
                              type="password"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col class="d-flex justify-space-between">
                            <v-btn
                              class="text-capitalize"
                              large
                              :disabled="!valid"
                              color="primary"
                              @click="submitForm"
                            >
                              Cadastrar
                            </v-btn>
                            <v-btn text @click="goToLogin">
                              Login
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-snackbar
                          v-model="success"
                          :timeout="3000"
                          top
                          right
                        >
                          Cadastro efetuado com sucesso!
                        </v-snackbar>
                        <v-snackbar
                          v-model="error"
                          :timeout="3000"
                          top
                          right
                          color="error"
                        >
                          {{ errorMessage }}
                        </v-snackbar>
                      </v-container>
                    </v-form>
                  </v-tab-item>
                </v-tabs>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      user: {
        email: '',
        nome: '',
        telefone: '',
        password: '',
        roleId: 3, 
      },
      valid: false,
      success: false,
      error: false,
      errorMessage: '',
    };
  },

  methods: {
    submitForm() {
        axios.post('http://173.212.228.61:3000/api/usuario/create', this.user)
          .then(response => {
            this.success = true;
            console.log(response.data);
            this.$router.push('/login');




          })
          .catch(error => {
            this.errorMessage = error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : 'Ocorreu um erro no cadastro';
            this.error = true;
          });
      }
    },
    goToLogin() {
      this.$router.push('/login');
    }
  };
</script>

<style scoped lang="scss">
@import "../../styles/app";

body {
  background-color: #fafafa !important;
}

.container {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  padding: 0;

  .main-part {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        text-align: center;
        font-family: "Roboto", sans-serif;
        font-size: 84px;
        color: $secondary;
        font-weight: 500;
      }
      .v-image {
        margin-bottom: 10px;
        width: 50%;
        height: 50vh;
      }
    }
  }

  .register-part {
    width: 100%;
    height: 100vh;
    overflow-y: auto;

    .register-wrapper {
      width: 50%;
      height: auto;

      .v-tabs {
        .v-tab {
          font-size: 18px;
        }
      }
    }
  }
}
</style>
