<template>
  <v-container fluid>
    <div class="dashboard-page">
      <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
        <h1 class="page-title">Dashboard</h1>
      </v-row>
      <v-row>
        <v-col lg="4" sm="4" md="4" cols="12">
          <v-card class="mx-1 mb-1" style="height: 250px">
            <v-card-title class="flex-nowrap pa-6 pb-3">
              <p>Chamadas</p>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pa-6 pt-0">
              <v-row no-gutters class="pb-5">
                <v-col cols="5" class="my-auto">
                  <span
                    class="font-weight-medium card-dark-grey"
                    style="font-size: 24px"
                    >366</span
                  >
                </v-col>
                <v-col cols="8">
                  <Trend
                    :data="getRandomDataForTrends()"
                    :gradient="mock.trend.gradient"
                    :height="40"
                    stroke-width="5"
                    smooth
                  />
                </v-col>
              </v-row>
              <v-row no-gutters class="justify-space-between pb-3">
                <v-col cols="5">
                  <div class="card-light-grey">Recebidas</div>
                  <div class="text-h6 card-dark-grey font-weight-regular">
                    324 <v-icon color="error"> mdi-arrow-bottom-right</v-icon>
                  </div>
                </v-col>
                <v-col cols="3">
                  <div class="card-light-grey">Efetuadas</div>
                  <div class="text-h6 card-dark-grey font-weight-regular">
                    32 <v-icon color="success"> mdi-arrow-top-right</v-icon>
                  </div>
                </v-col>
                <v-col cols="4" xl="2">
                  <div class="text-right card-light-grey">Perdidas</div>
                  <div
                    class="text-right text-h6 card-dark-grey font-weight-regular"
                  >
                    10
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col lg="4" sm="4" md="4" cols="12">
          <v-card class="mx-1 mb-1" style="height: 250px">
            <v-card-title class="flex-nowrap pa-6 pb-3">
              <p class="text-truncate">Ligações da URA</p>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pa-6 pt-0 mb-1">
              <v-row no-gutters>
                <v-col cols="12">
                  <ApexChart
                    height="124"
                    type="donut"
                    class="mt-1"
                    :options="mock.apexPie.options"
                    :series="generatePieSeries()"
                  ></ApexChart>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="4" sm="4" md="4" cols="12">
          <v-card class="mx-1 mb-1" style="height: 250px">
            <v-card-title class="pa-6 pb-3">
              <p>Ramais</p>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pa-6 pt-0">
              <v-row no-gutters>
                <v-col cols="7" class="my-auto">
                  <span class="" style="font-size: 42px">121 </span>
                </v-col>
                <v-col cols="5">
                  <ApexChart
                    height="100"
                    type="bar"
                    v-if="apexLoading"
                    :options="mock.apexBar2.options"
                    :series="mock.apexBar2.series"
                  ></ApexChart>
                </v-col>
              </v-row>
              <v-row no-gutters class="justify-space-between">
                <div>
                  <div class="subtext">
                    55
                  </div>
                  <div class="subtext-index">Disponíveis</div>
                </div>
                <div>
                  <div class="subtext">
                    18
                  </div>
                  <div class="subtext-index">Ocupados</div>
                </div>
                <div>
                  <div class="subtext">
                    48
                  </div>
                  <div class="subtext-index">Indisponíveis</div>
                </div>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="mx-1 mb-1">
            <v-card-title class="pa-6 pb-0">
              <v-row no-gutters>
                <v-col
                  cols="7"
                  sm="4"
                  md="4"
                  lg="5"
                  class="d-flex align-center"
                >
                  <p>Link de Voz</p>
                </v-col>
                <v-col
                  sm="6"
                  md="6"
                  lg="5"
                  class="d-none d-sm-flex align-center"
                >
                  <v-icon size="18" color="warning">mdi-circle-medium</v-icon
                  ><span
                    class="card-dark-grey font-weight-regular mr-3"
                    style="font-size: 18px"
                    >Entrante</span
                  >
                  <v-icon size="18" color="primary">mdi-circle-medium</v-icon
                  ><span
                    class="card-dark-grey font-weight-regular mr-3"
                    style="font-size: 18px"
                    >Sainte</span
                  >
                </v-col>
                <v-col cols="5" sm="2" md="2" lg="1" offset-lg="1">
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-select
                        class="main-chart-select"
                        v-model="mainApexAreaSelect"
                        v-bind="attrs"
                        v-on="on"
                        dense
                        flat
                        single-line
                        hide-details
                        :items="mock.select"
                        outlined
                      ></v-select>
                    </template>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="pa-6">
              <v-row>
                <v-col>
                  <ApexChart
                    v-if="apexLoading"
                    height="350"
                    type="area"
                    :options="mock.mainApexArea.options"
                    :series="
                      mainApexAreaSelect === 'Dia'
                        ? mock.mainApexArea.series
                        : mainApexAreaSelect === 'Semana'
                        ? mock.mainApexArea.series2
                        : mock.mainApexArea.series3
                    "
                  ></ApexChart>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="4" sm="4" md="4" cols="12">
          <v-card class="mx-1 mb-1" style="height: 250px">
            <v-card-title class="flex-nowrap pa-6 pb-3">
              <p>Uso de hardware</p>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pa-6 pt-0">
              <v-row no-gutters class="pb-5">
                <div class="mr-4">
                  <v-icon color="primary" class="ml-n2">
                    mdi-circle-medium
                  </v-icon>
                  <span class="card-light-grey">RAM</span>
                </div>
                <div class="mr-4">
                  <v-icon color="warning" class="ml-n2">
                    mdi-circle-medium
                  </v-icon>
                  <span class="card-light-grey">CPU</span>
                </div>
                <div class="mr-4">
                  <v-icon color="red" class="ml-n2">
                    mdi-circle-medium
                  </v-icon>
                  <span class="card-light-grey">Swap</span>
                </div>
              </v-row>
              <v-row no-gutters class="pb-3">
                <v-col>
                  <div class="text-h6 card-light-grey font-weight-regular">
                    RAM
                  </div>
                  <v-progress-linear
                    :value="value"
                    background-color="#ececec"
                    color="primary"
                  ></v-progress-linear>
                </v-col>
              </v-row>
              <v-row no-gutters class="pb-1">
                <v-col>
                  <div class="text-h6 card-light-grey font-weight-regular">
                    CPU
                  </div>
                  <v-progress-linear
                    :value="value2"
                    background-color="#ececec"
                    color="warning"
                  ></v-progress-linear>
                </v-col>
              </v-row>
              <v-row no-gutters class="pb-1">
                <v-col>
                  <div class="text-h6 card-light-grey font-weight-regular">
                    Swap
                  </div>
                  <v-progress-linear
                    :value="value"
                    background-color="#ececec"
                    color="red"
                  ></v-progress-linear>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="4" sm="4" md="4" cols="12">
          <v-card class="mx-1 mb-1" style="height: 250px">
            <v-card-title class="flex-nowrap pa-6 pb-3">
              <p>Carga do Sistema</p>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pa-6 pt-0">
              <v-row no-gutters>
                <v-col cols="7" class="my-auto">
                  <span class="" style="font-size: 42px">0.56 </span>
                </v-col>
                <v-col cols="5">
                  <ApexChart
                    height="100"
                    type="bar"
                    v-if="apexLoading"
                    :options="mock.apexBar2.options"
                    :series="mock.apexBar2.series"
                  ></ApexChart>
                </v-col>
              </v-row>
              <v-row no-gutters class="justify-space-between">
                <div>
                  <div class="subtext">
                    0.49
                  </div>
                  <div class="subtext-index">Minuto</div>
                </div>
                <div>
                  <div class="subtext">
                    0.46
                  </div>
                  <div class="subtext-index">5 Minutos</div>
                </div>
                <div>
                  <div class="subtext">
                    0.35
                  </div>
                  <div class="subtext-index">15 Minutos</div>
                </div>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="4" sm="4" md="4" cols="12">
          <v-card class="mx-1 mb-1" style="height: 250px">
            <v-card-title class="flex-nowrap pa-6 pb-3">
              <p>Uso do Disco</p>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pa-6 pt-0">
              <v-row no-gutters>
                <v-col cols="7" class="my-auto">
                  <span class="" style="font-size: 42px">Boot / </span>
                </v-col>
                <v-col cols="5">
                  <ApexChart
                    height="100"
                    type="bar"
                    v-if="apexLoading"
                    :options="mock.apexBar3.options"
                    :series="mock.apexBar3.series"
                  ></ApexChart>
                </v-col>
              </v-row>
              <v-row no-gutters class="justify-space-between">
                <div>
                  <div class="subtext">
                    ext4
                  </div>
                  <div class="subtext-index">Filesystem</div>
                </div>
                <div>
                  <div class="subtext">
                    265MB/ 999MB
                  </div>
                  <div class="subtext-index">Espaço</div>
                </div>
                <div>
                  <div class="subtext">
                    0.4KB/ 65.5KB
                  </div>
                  <div class="subtext-index">inode</div>
                </div>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="support-requests mx-1 mb-1">
            <v-card-title class="pa-6 pb-0">
              <p>Status dos Links</p>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-simple-table>
                <template v-slot:default>
                  <thead class="pl-2">
                    <tr>
                      <th class="text-left pa-6">Nome</th>
                      <th class="text-left">Tipo</th>
                      <th class="text-left">Interface</th>
                      <th class="text-left">Sinal</th>
                      <th class="text-left">STATUS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in mock.table" :key="item.name">
                      <td class="pa-6">{{ item.name }}</td>
                      <td>{{ item.type }}</td>
                      <td>{{ item.interface }}</td>
                      <td>{{ item.sinal }}</td>
                      <td v-if="item.status === 'Sent'">
                        <v-icon style="font-size: 28px" link color="success"
                          >mdi-check
                        </v-icon>
                      </td>
                      <td v-else-if="item.status === 'Declined'">
                        <v-icon style="font-size: 28px" link color="error"
                          >mdi-alert-circle-outline
                        </v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import mock from "./mock";
import Trend from "vuetrend";
import ApexChart from "vue-apexcharts";

export default {
  name: "Dashboard",
  components: {
    Trend,
    ApexChart,
  },
  data() {
    return {
      mock,
      apexLoading: false,
      value: this.getRandomInt(10, 90),
      value2: this.getRandomInt(10, 90),
      mainApexAreaSelect: "Diário",
    };
  },
  methods: {
    getRandomDataForTrends() {
      const arr = [];
      for (let i = 0; i < 12; i += 1) {
        arr.push(Math.random().toFixed(1) * 10);
      }
      return arr;
    },
    generatePieSeries() {
      let series = [];

      for (let i = 0; i < 4; i++) {
        let y = Math.floor(Math.random() * (500 - 100 + 100)) + 100;
        series.push(y);
      }
      return series;
    },
    getRandomInt(min, max) {
      let rand = min - 0.5 + Math.random() * (max - min + 1);
      return Math.round(rand);
    },
  },
  mounted() {
    setTimeout(() => {
      this.apexLoading = true;
    });
  },
};
</script>

<style src="./Dashboard.scss" lang="scss" />
