<template>
    <v-container fluid>
      <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
        <h1 class="page-title">Gerenciamento de Troncos</h1>
        <v-dialog v-model="dialog" max-width="900px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Novo Tronco
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <template>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="nome"
                        label="Nome"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-model="tech_prefix"
                        label="Tech Prefix"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-model="usarname"
                        label="Username"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-model="fromuser"
                        label="Fromuser"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="secret"
                        label="Secret"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-model="host"
                        label="Host"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-model="config"
                        label="Configuração especifica"
                        required
                      ></v-text-field>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-switch label="Registrar" v-model="registrar"></v-switch>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-switch label="Gravar" v-model="gravar"></v-switch>
                        </v-col>
                    </v-row>
                    </v-col>
                  </v-row>
                </v-form>
              </template>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="warning" @click="resetValidation">
                Limpar
              </v-btn>
              <v-btn color="error" class="mr-4" @click="close">
                Cancelar
              </v-btn>
              <v-btn color="success" class="mr-4" @click="save">
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        
      </v-row>
      <template>
        <v-card color="grey lighten-4" flat height="60px" tile>
          <v-toolbar dense>
            <v-spacer></v-spacer>
            <v-card-title class="pa-1 pb-0">
              <v-spacer></v-spacer>
              <v-text-field
                append-icon="mdi-magnify"
                label="Pesquisar"
                clearable
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
          </v-toolbar>
        </v-card>
      </template>
      <v-data-table :headers="headers" :items="desserts" class="elevation-1">
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small class="mr-2" v-on:click="alertDisplay">
            mdi-delete
          </v-icon>
          <v-icon @click="undo(item)">
            mdi-undo
          </v-icon>
        </template>
      </v-data-table>
    </v-container>
  </template>
  
  <script>
  export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "Tronco", value: "tronco" },
        { text: "Nome", value: "nome" },
        { text: "Gravação", value: "gravar" },
        { text: "Tech Prefix", value: "tech_prefix" },
        { text: "Usuário", value: "username" },
        { text: "Host", value: "host" },
        { text: "Status", value: "status" },
        { text: "Ações", value: "actions", sortable: false },
      ],
      items: [],
      editedIndex: -1,
      editedItem: {
        gravar: false,
        nome: "",
        tech_prefix: "",
        usarname: "",
        fromuser: "",
        secret: "",
        status: "", 
        host: "",
      },
      defaultItem: {
        gravar: false,
        nome: "",
        tech_prefix: "",
        usarname: "",
        fromuser: "",
        secret: "",
        status: "", 
        host: "",
      },
    }),
  
    computed: {
      formTitle() {
        return this.editedIndex === -1 ? "Novo Tronco" : "Editar Tronco";
      },
    },
  
    watch: {
      dialog(val) {
        val || this.close();
      },
      dialogDelete(val) {
        val || this.closeDelete();
      },
    },
  
    created() {
      this.initialize();
    },
  
    methods: {
      initialize() {
        this.desserts = [
          //get
        ];
      },
  
      editItem(item) {
        this.editedIndex = this.desserts.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      },
  
      close() {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },
  
      closeDelete() {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },
  
      save() {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem);
        } else {
          this.desserts.push(this.editedItem);
        }
        this.close();
      },
      resetValidation() {
        this.$refs.editedItem.resetValidation();
      },
      undo: function() {
        if (this.canUndo) {
          this.editedIndex -= 1;
          this.desserts = this.history[this.editedIndex];
        }
      },
      alertDisplay() {
        this.$swal({
          title: "Deseja excluir este Tronco?",
          text: "O tronco será excluído do banco de dados",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3CD4A0",
          cancelButtonColor: "#E53935",
          confirmButtonText: "Sim, excluir!",
        }).then((result) => {
          if (result.value) {
            this.$swal("Excluído", "Tronco excluído com sucesso!", "success");
          } else {
            this.$swal("Exclusão Cancelada!");
          }
        });
      },
    },
  };
  </script>
  