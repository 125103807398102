<template>
  <v-container fluid>
    <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
      <h1 class="page-title">Usuários do Sistema</h1>
      <v-dialog v-model="dialog" max-width="500px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
            Novo Usuário
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <template>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-col cols="12" v-if="!isEditing">
                  <v-text-field
                    v-model="user.nome"
                    label="Nome"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="user.email"
                    label="Email"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="user.telefone"
                    label="Telefone"
                    type="number"
                    inputmode="numeric"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="user.password"
                    label="Senha"
                    type="password"
                    required
                  ></v-text-field>
                  <v-select
                    v-model="user.roleId"
                    :items="roleId"
                    item-text="roleId"
                    item-value="id"
                    :rules="[(v) => !!v || 'Selecione Hierarquia']"
                    label="Hierarquia"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" v-if="isEditing">
                  <v-select
                    v-model="editedItem.tenantId"
                    :items="tenants"
                    item-text="razao_social"
                    item-value="id"
                    label="Tenant"
                    :rules="[(v) => !!v || 'Selecione um Tenant']"
                    required
                  ></v-select>
                </v-col>
              </v-form>
            </template>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" @click="resetValidation">
              Limpar
            </v-btn>
            <v-btn color="error" class="mr-4" @click="close">
              Cancelar
            </v-btn>
            <v-btn color="success" class="mr-4" @click="save">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="desserts"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Tem certeza de que deseja excluir este item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" class="mr-4" @click="closeDelete">
                  Cancelar
                </v-btn>
                <v-btn color="success" class="mr-4" @click="deleteItemConfirm">
                  Excluir
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small class="mr-2" @click="deleteItem(item)">
          mdi-delete
        </v-icon>
        <v-icon @click="undo(item)">
          mdi-undo
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    roleId: ["ADMINISTRADOR", "SUPORTE", "CLIENTE"],

    user: {
        email: '',
        nome: '',
        telefone: '',
        password: '',
        roleId: '',
    },

    headers: [
      { text: "Nome", value: "nome" },
      { text: "Email", value: "email"},
      { text: "Hierarquia", value: "roleName" },
      { text: "Ações", value: "actions", sortable: false },
    ],

    desserts: [],
    tenants: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      tenantId: "",
    },
    defaultItem: {
      
      nome: "",
      senha: "",
      email: "",
      roleId: "",
      tenantId: "",
    },
    roleMapping: {
      1: "ADMINISTRADOR",
      2: "SUPORTE",
      3: "CLIENTE"
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Novo Usuário" : "Editar Usuário";
    },
    isEditing() {
      return this.editedIndex !== -1; 
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
    this.getDesserts();
    this.getTenants();
  },

  methods: {
    initialize() {
      this.desserts = [];
    },

    async getTenants() {
      try {
        const token = localStorage.getItem('token');

        const response = await axios.get('http://173.212.228.61:3000/api/tenant', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        this.tenants = response.data;
      } catch (error) {
        console.error("Erro ao buscar tenants:", error);
      }
    },

    async getDesserts() {
      try {
        const response = await axios.get('http://173.212.228.61:3000/api/usuario');
        this.desserts = response.data.map(user => ({
          ...user,
          roleName: this.roleMapping[user.roleId]
        }));
      } catch (error) {
        console.error("Erro:", error);
      }
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      try {
        if (this.isEditing){ 
          if (this.editedItem.tenantId) {
            await axios.patch('http://173.212.228.61:3000/api/usuario/anexar/tenant', {
              userId: this.editedItem.id,
              tenantId: this.editedItem.tenantId,
            });
          }
        } else {
          await axios.post('http://173.212.228.61:3002/api/usuario/create/client', this.user)
            .then(response => {
              this.success = true;
              console.log(response.data);
            })
            .catch(error => {
              this.errorMessage = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : 'Ocorreu um erro no cadastro';
              this.error = true;
            });
        }
      } catch (error) {
        console.error(error);
      }
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
