import Vue from "vue";
import Router from "vue-router";

import Layout from "@/components/Layout/Layout";

// Pages
import Dashboard from "@/pages/Dashboard/Dashboard";
import Links from "@/pages/Links/Links";
import Status from "@/pages/Status/Status";
import Ramais from "@/pages/Ramais/Ramais";
import Agenda from "@/pages/Agenda/Agenda";
import Filas from "@/pages/Filas/Filas";
import Tarifa from "@/pages/Tarifa/Tarifa";
import Chamadas from "@/pages/Chamadas/Chamadas";
import Salas from "@/pages/Salas/Salas";
import Informacoes from "@/pages/Informacoes/Informacoes";
import Icons from "@/pages/Icons/Icons";
import Graficos from "@/pages/Graficos/Graficos";
import Error from "@/pages/Error/Error";
import Login from "@/pages/Login/Login";
import Agendas from "@/pages/Agendas/Agendas";
import URA2 from "@/pages/URA2/URA2";
import Gravacoes from "@/pages/Gravacoes/Gravacoes";
import Rotas from "@/pages/Rotas/Rotas1";
import Rotas2 from "@/pages/Rotas2/Rotas2";
import Rotas3 from "@/pages/Rotas3/Rotas3";
import Rotas4 from "@/pages/Rotas4/Rotas4";
import Usuarios from "@/pages/Usuarios/Usuarios";
import Grupos from "@/pages/Grupos/Grupos";
import Permissoes from "@/pages/Permissoes/Permissoes";
import Singin from "@/pages/SingIn/Singin";
import Troncos from "./pages/Troncos/Troncos.vue";
import Contexts from "./pages/Context/Contexts.vue";
import RamaisLote from "./pages/RamaisLote/RamaisLote.vue";
import Tenants from "./pages/Tenants/Tenants.vue";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/singin",
      name: "Singin",
      component: Singin,
    },
    {
      path: "/",
      redirect: "login",
      name: "Layout",
      component: Layout,
      children: [
        {
          path: "dashboard",
          name: "Dashboard",
          component: Dashboard,
          meta: { requiresAuth: true },
        },
        {
          path: "Permissoes",
          name: "Permissoes",
          component: Permissoes,
          meta: { requiresAuth: true },
        },
        {
          path: "Usuarios",
          name: "Usuarios",
          component: Usuarios,
          meta: { requiresAuth: true },
        },
        {
          path: "Grupos",
          name: "Grupos",
          component: Grupos,
          meta: { requiresAuth: true },
        },
        {
          path: "Rotas1",
          name: "Rotas",
          component: Rotas,
          meta: { requiresAuth: true },
        },
        {
          path: "Rotas2",
          name: "Rotas",
          component: Rotas2,
          meta: { requiresAuth: true },
        },
        {
          path: "Rotas3",
          name: "Rotas",
          component: Rotas3,
          meta: { requiresAuth: true },
        },
        {
          path: "Rotas4",
          name: "Rotas",
          component: Rotas4,
          meta: { requiresAuth: true },
        },
        {
          path: "Links",
          name: "Links",
          component: Links,
          meta: { requiresAuth: true },
        },
        {
          path: "Agendas",
          name: "Agendas",
          component: Agendas,
          meta: { requiresAuth: true },
        },
        {
          path: "Status",
          name: "Status",
          component: Status,
          meta: { requiresAuth: true },
        },
        {
          path: "URA2",
          name: "URA2",
          component: URA2,
          meta: { requiresAuth: true },
        },
        {
          path: "Tarifa",
          name: "Tarifa",
          component: Tarifa,
          meta: { requiresAuth: true },
        },
        {
          path: "Chamadas",
          name: "Chamadas",
          component: Chamadas,
          meta: { requiresAuth: true },
        },
        {
          path: "Ramais",
          name: "Ramais",
          component: Ramais,
          meta: { requiresAuth: true },
        },
        {
          path: "Troncos",
          name: "Troncos",
          component: Troncos,
          meta: { requiresAuth: true },
        },
        {
          path: "Filas",
          name: "Filas",
          component: Filas,
          meta: { requiresAuth: true },
        },
        {
          path: "Salas",
          name: "Salas",
          component: Salas,
          meta: { requiresAuth: true },
        },
        {
          path: "Gravacoes",
          name: "Gravacoes",
          component: Gravacoes,
          meta: { requiresAuth: true },
        },
        {
          path: "Informacoes",
          name: "Informacoes",
          component: Informacoes,
          meta: { requiresAuth: true },
        },
        {
          path: "Agenda",
          name: "Agenda",
          component: Agenda,
          meta: { requiresAuth: true },
        },
        {
          path: "icons",
          name: "Icons",
          component: Icons,
          meta: { requiresAuth: true },
        },
        {
          path: "Graficos",
          name: "Graficos",
          component: Graficos,
          meta: { requiresAuth: true },
        },
        {
          path: "contexts",
          name: "Contexts",
          component: Contexts,
          meta: { requiresAuth: true },
        },
        {
          path: "ramais_lote",
          name: "ramais_lote",
          component: RamaisLote,
          meta: { requiresAuth: true },
        },
        {
          path: "tenants",
          name: "tenants",
          component: Tenants,
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      path: "*",
      name: "Error",
      component: Error,
    },
  ],
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = window.localStorage.getItem("authenticated") === "true";
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
